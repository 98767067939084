<template>
    <div>
        <v-dialog 
            v-model="visible" 
            transition="dialog-top-transition" 
            scrollable 
            persistent 
            max-width="1200"
        >
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text
                    v-if="loading"
                >
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        {{ $t("Загрузка...") }}
                    </v-col>

                    <v-col cols="12">
                        <v-progress-linear
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-col>

                </v-card-text>

                <v-card-text 
                    v-else
                    class="wrapperFormModal"
                >
                    
                    <v-tabs
                        v-model="tab"
                        grow
                        background-color="#e3e3ee"
                        class="tab-selector-panel"
                        slider-size="3"
                        mobile-breakpoint="0"                    
                    >
                        <v-tabs-slider color="#E43958"></v-tabs-slider>
                        <!--
                        <v-tab>
                            {{ $t("Политика_конфиденциальности") }}
                        </v-tab>
                        -->
                        <v-tab>
                            {{ $t("Пользовательское_соглашение") }}
                        </v-tab>

                        <v-tab>
                            {{ $t("Соглашение_о_персональных_данных") }}
                        </v-tab>

                    </v-tabs>

                    <v-tabs-items v-model="tab" class="tab-content-panel">
                        <!--
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="agreement-card-wrappertext" v-html="tabContent.confidential"></div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        -->
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="agreement-card-wrappertext" v-html="tabContent.termsUse"></div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="agreement-card-wrappertext" v-html="tabContent.personalData"></div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>  

                    <v-card v-if="!viewonly" flat>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="12">
                                    <div class="onlyReadData">
                                        <v-checkbox
                                            v-model="agreementCheck1"
                                            :label="$t('Подтверждение_ПС')"
                                            color="primary"
                                            hide-details
                                            dense
                                        ></v-checkbox>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <div class="onlyReadData">
                                        <v-checkbox
                                            v-model="agreementCheck2"
                                            :label="$t('Подтверждение_СИПД')"
                                            color="primary"
                                            hide-details
                                            dense
                                        ></v-checkbox>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>             
                
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        v-show="agreementCheck1 && agreementCheck2"
                        color="cyan" 
                        text
                        depressed
                        @click="ok"
                    >
                        {{$t("ПринятьU")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import i18n from '@/i18n';
/*import sys from '@/services/system';
import { httpAPI } from '@/api/httpAPI';*/
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "AgreementDlg",
    data () {
        return {
            title: "Соглашения",
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            source: null,
        }
    },
    computed: {
        ...mapGetters('global/dialogs/userAgreement',
        {
            visible: 'isVisible',
            loading: 'isLoading',
            viewonly: 'getViewOnly',
            check1: 'getCheck1',
            check2: 'getCheck2',
            tabContent: 'getTabContent',
        }),
        agreementCheck1:{
            get: function() { return this.check1; },
            set: function(value) { this.$store.commit('global/dialogs/userAgreement/SET_CHECK1', value );}
        },
        agreementCheck2:{
            get: function() { return this.check2; },
            set: function(value) { this.$store.commit('global/dialogs/userAgreement/SET_CHECK2', value );}
        },
        tab:{
            get: function() {return this.$store.getters['global/dialogs/userAgreement/getTab']},
            set: function(value) {this.$store.commit('global/dialogs/userAgreement/SET_TAB', value)}
        }
    },
    methods: {
        ...mapActions('global/dialogs/userAgreement', [ 'ok', 'cancel' ]),
    },
}
</script>