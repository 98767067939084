<template>
    <div>
        <component 
            :is="loginScreen"
        />
    </div>
</template>

<script>
import store from '@/store';
import { httpAPI } from "@/api/httpAPI";
import LoginIAS from '@/views/global/LoginFull.vue';
import LoginSED from '@/views/global/LoginFullSED.vue';
import LoginARM from '@/views/global/LoginFullARM.vue';
import LoginNoGts from '@/views/global/LoginNoGts.vue';
export default {
    name: "Login",
    components: {
        LoginIAS,
        LoginSED,
        LoginARM,
        LoginNoGts
    },
    asyncComputed: {
        loginScreen: {
            async get() { 
                let response = await httpAPI({
                    url: `api/references/getclientconfig`,
                    method: 'GET'
                });
                if (response){
                    let cliConf = response.data.Payload;
                    store.commit('SET_CLIENT_CONFIG', cliConf);
                    if (cliConf.Config === "SED")
                        return 'LoginSED';
                    if (cliConf.Config === "ARM")
                        return 'LoginARM';
                    if (cliConf.Config === "NoGts")
                        return 'LoginNoGts';
                }
                return 'LoginIAS';
            },
            default: () => ('LoginIAS')
        }
    }
}
</script>